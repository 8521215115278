<template>
<div class="market">
    <div class="market-bx" style="padding-bottom: 0;">
  <div class="marketPay_content">
    <div class="subject">
      <div class="title">
        <img class="title_img" :src="List.nftPath" alt="" />
        <div class="title_content">
          <div class="title_name">{{ List.code }}</div>
          <div class="title_logo">
            <div class="title_solana" v-if="List.nftId">
              <img :src="List.chain === 'Solana' ? '/img/SOLANA.png' : (List.chain === 'Ethereum' ? '/img/marketgroup9copy.png' : '/img/marketgroup10copy.png')" alt="" />
              <span>{{ List.chain }}</span>
            </div>
            <img style="width: 218px; 70px;" :src="introduce.logoZhTw" alt="">
          </div>
          <div class="introduce">
            <div class="introduce_top">
              <div v-for="item in attr" :key="item.index" class="introduce_text">
                <p>{{ item.head }}</p>
                <p style="color: #fff;">{{ item.body }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pay">
        <el-divider></el-divider>
        <div class="pay_option">
          <div class="price" v-if="List.nftId">{{ priceDispose(List.price) + '&nbsp;' + List.currency }}</div>
          <div class="way" v-if="List.nftId">
            <div
              class="choice_pay"
              v-for="item in optionList"
              :key="item.id"
              @click="optionBtn(item)"
            >
              <img class="select" :src="item.img" alt="" />
              <img
                v-show="optionId === item.id"
                class="gou"
                src="/img/Group2Copy4@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="proceed_pay" @click="payBtn">
            <img src="/img/Pay@2x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <pay v-if="payShow" :id="id" @payOutBtn="payOutBtn" class="login_box"></pay>
  </div>
    </div>
  </div>
</template>

<script>
import { marketDetails, getNftCommodityByOrderNo, getCategoryData, } from '@/api/api'
import { newAttr, priceDispose } from '../../common/common'
import Pay from './components/pay.vue'
export default {
  components: {
    Pay
  },
  data() {
    return {
      priceDispose,
      introduce: [],
      payShow: false,
      id: {
        nftId: this.$route.query.nftId,
        nftCommodityId: this.$route.query.nftCommodityId,
        orderNo: this.$route.query.orderNo
      },
      orderNo: this.$route.query.orderNo,
      List: {},
      attr: [],
      optionList: [
        // {
        //   id: 1,
        //   img: '/img/option@2x.png',
        // },
        // {
        //   id: 2,
        //   img: '/img/pay9@2x.png',
        // },
        {
          id: 3,
          img: '/img/pay7@2x.png',
        },
      ],
      optionId: 0, // 确定支付方式
    }
  },
  created() {
    if(this.id.nftCommodityId) {
      this.marketDetails()
    }
    if(this.orderNo) {
      this.getNftCommodityByOrderNo()
    }
  },
  methods: {
    async getCategoryData() {
      const res = await getCategoryData(this.List.category)
      this.introduce = res.data[0]
    },
    async getNftCommodityByOrderNo() {
      const res = await getNftCommodityByOrderNo(this.orderNo)
      this.id.nftId = res.data.nftId
      this.id.nftCommodityId = res.data.nftCommodityId
      this.List = res.data
      this.getCategoryData()
    },
    payOutBtn() {
      this.payShow = false
      this.optionId = 0
    },
    async marketDetails() {
      const res = await marketDetails(this.id.nftCommodityId)
      this.List = res.data
      this.attr = newAttr(this.List.attr)
      this.getCategoryData()
    },
    payBtn() {
      if(this.optionId === 0) return this.$message.warning('請選擇支付方式')
        this.payShow = true
    },
    optionBtn(item) {
      if (item.id === 1 || item.id === 2)
        return this.$message.warning('暫不支持此方式支付,請選擇stripe支付')
      this.optionId = item.id
    },
  },
}
</script>

<style>
.my-input {
    padding: 10px;
    border: 1px solid #ccc;
  }
</style>
